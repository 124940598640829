@font-face {
  font-family: "Source Code Pro", monospace;
  src: url("./assets/SourceCodePro-Regular.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Source Code Pro", monospace !important;
  overflow-x: hidden;
  min-height: calc(100vh - 35px);
}
